import "./App.css";
import XMLParser from "react-xml-parser";
import React, { useEffect, useState } from "react";
import logo from "./img/logo.png";
import axios from "axios";
import arrow from './img/arrow.png'
import companies from "./img/life-insurance.png"
import styled from 'styled-components'


function App() {
  const [inputs, setInputs] = useState(null);
  const [infos, setInfos] = useState(null);
  const [jsonData, setjsonData] = useState(null);
  const [mobileNumberValid, setMobileNumberValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  console.log(arrow);

  const validateMobileNumber = (number) => {
    const pattern = /^(1(-)?)?([2-9]\d{2}(-)?)\d{3}(-)?\d{4}$/;
    return pattern.test(number);
  }


  const validateEmail = (email) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }

  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };


  const MainContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 16px;
`

  const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 60px;
  position: relative;
  :before {
    content: '';
    position: absolute;
    background: #f3e7f3;
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: '';
    position: absolute;
    background: #47C9FF;
    height: 4px;
    width: ${({ width }) => width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`

  const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`

  const StepStyle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 3px solid
    ${({ step }) => (step === 'completed' ? '#47C9FF' : '#F3E7F3')};
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`

  const StepCount = styled.span`
  font-size: 19px;
  font-weight: 700;
  color: #933CFE;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`

  const StepsLabelContainer = styled.div`
  position: absolute;
  text-align: center;
  top: 66px;
  left: 50%;
  transform: translate(-50%, -50%);
`

  const StepLabel = styled.span`
  font-size: 19px;
  color: #4a154b;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`

  const CheckMark = styled.div`
  font-size: 26px;
  font-weight: 600;
  color: #4a154b;
  -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
  -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
`


  const steps = [
    {
      label: 'Coverage Info',
      step: 1,
    },
    {
      label: 'Applicant Info',
      step: 2,
    },
    {
      label: 'Contact Info',
      step: 3,
    },
    {
      label: 'Free Quote',
      step: 4,
    },
  ]

  const totalSteps = steps.length

  const width = `${(100 / (totalSteps - 1)) * (step - 1)}%`

  const [formData, setFormData] = useState({
    lc: "",
    pi: "",
    fa: "",
    FirstName: "",
    mm: "",
    dd: "",
    yy: "",
    g: "",
    tucgt: "", 
    email: "",
    number: "",
  });

  useEffect(() => {}, []);

  const handleChange = (e) => {
    if(e.target.name==='number'){
      setMobileNumberValid(validateMobileNumber(e.target.value));
    }
    if(e.target.name==='email'){
      setEmailValid(validateEmail(e.target.value));
    }
    setFormData({ ...formData, [e.target.name]: e.target.value })
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    setInfos((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let info = {...inputs}
    let info2 = {...infos}

    const url = `https://procheshta.onrender.com/clientinfoupload`;

         fetch(url,{
           method:"POST",
           headers:{
               "Content-Type":"application/json"
           },
           body: JSON.stringify(info2)
         })
         
    axios
      .get(
        `https://www.winquote.net/cgi-bin/compete.pl?dc=-cv1.5%20-ccca%20-pcca1673564771%20-qt0%20-rt0%20-lc${info.lc}%20-dob${info.mm}15${info.yy}%20-g${info.g}%20-tucgt${info.tucgt}%20-rR%20-pg0%20-pi${info.pi}%20-rc0%20-langen%20-fmt%20-fa${info.fa}%20-pm3`
      )
      .then((res) => {
        setjsonData(new XMLParser().parseFromString(res?.data))
        setInputs(null)
      });
  };
  let data;
  const showData = () =>{
    data = jsonData?.children[2]?.children?.slice(0,5).map((item) => {
      return item
    });
  }
  showData()
  console.log(jsonData);

  return (
    <>
      {jsonData ? <div className="popup">
        <a onClick={(e) => setjsonData(null)} className="d-block mt-5 m-auto" style={{ width: "500px", position: "relative" }}>
          <img src={arrow} alt="" onClick={() => window.location.reload(true)} width={29} style={{ position: "absolute", top: "10px", cursor:"pointer" }}/>
          <div className="result-logo">
          <img src={logo} width={200} className="d-block m-auto mb-4" alt="policyGemLogo" />
          </div>
        </a>
        <ul>
          {data?.map((item) => (
            <li className="single-company">
              <h4>{item.children[1]?.children[0]?.value}</h4>
              <div className="company-service">
                <p>{item.children[1]?.children[1]?.value}</p>
                <h4>{item.children[1]?.children[7]?.value}/<span>{item.children[1]?.children[4]?.value}</span></h4>
              </div>
              <a href="tel:(905) 818-3317">Call (905) 818-3317</a>
            </li>
          ))}
        </ul>
      </div> : ""}


      <div className="mainform">
        <a href="https://policygem.ca/">
          <img src={logo} width={200} className="d-block m-auto mb-4" alt="" />
        </a>
        <div className="container whole-form">
          <ul>
            <li className="active">Life Insurance Quote</li>
          </ul>
          <div className="" id="indicator">
          <MainContainer>
            <StepContainer width={width}>
              {steps.map(({ step, label }) => (
                <StepWrapper key={step}>
                  <StepStyle step={step >= step ? 'completed' : 'incomplete'}>
                    {step > step ? (
                      <CheckMark>L</CheckMark>
                    ) : (
                      <StepCount>{step}</StepCount>
                    )}
                  </StepStyle>
                  <StepsLabelContainer>
                    <StepLabel key={step}>{label}</StepLabel>
                  </StepsLabelContainer>
                </StepWrapper>
              ))}
            </StepContainer>
          </MainContainer>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="from-part row">

              {step === 1 && (<div>
                <div className="col-lg-12 form-group">
                  <label htmlFor="location">Select Location</label>
                  <select id="location" value={formData.lc} name="lc" className="form-control" onChange={handleChange} required>
                    <option value="">Select Location</option>
                    <option value="0">British Columbia</option>
                    <option value="1">Alberta</option>
                    <option value="2">Saskatchewan</option>
                    <option value="3">Northwest Territories</option>
                    <option value="4">Yukon</option>
                    <option value="5">Nunavut</option>
                    <option value="6">Manitoba</option>
                    <option value="7">Ontario</option>
                    <option value="8">Quebec</option>
                    <option value="9">Nova Scotia</option>
                    <option value="10">New Brunswick</option>
                    <option value="11">Prince Edward Island</option>
                    <option value="12">Newfoundland & Labrador</option>
                    <option value="13">All Provinces</option>
                  </select>
                </div>
                <div className="col-lg-12 form-group">
                  <label htmlFor="typeofinsurance">Type of insurance</label>
                  <select id="typeofinsurance" value={formData.pi} name="pi" className="form-control" onChange={handleChange} required>
                    <option value="">Select Term</option>
                    <option value="2">10 Year Term</option>
                    <option value="3">15 Year Term</option>
                    <option value="4">20 Year Term</option>
                    <option value="5">25 Year Term</option>
                    <option value="6">30 Year Term</option>
                    <option value="7">35 Year Term</option>
                    <option value="8">40 Year Term</option>
                    <option value="9">Level Term to 65</option>
                    <option value="10">Level Term to 70</option>
                    <option value="11">Level Term to 75</option>
                  </select>
                </div>
                <div className="col-lg-12 form-group">
                  <label htmlFor="reqcoverage">Required Coverage</label>
                  <select id="reqcoverage" value={formData.fa} name="fa" className="form-control" onChange={handleChange} required>
                    <option value="">Select Coverage</option>
                    <option value="50000">$50,000</option>
                    <option value="75000">$75,000</option>
                    <option value="100000">$100,000</option>
                    <option value="150000">$150,000</option>
                    <option value="200000">$200,000</option>
                    <option value="250000">$250,000</option>
                    <option value="350000">$350,000</option>
                    <option value="400000">$400,000</option>
                    <option value="450000">$450,000</option>
                    <option value="500000">$500,000</option>
                    <option value="600000">$600,000</option>
                    <option value="700000">$700,000</option>
                    <option value="750000">$750,000</option>
                    <option value="800000">$800,000</option>
                    <option value="900000">$900,000</option>
                    <option value="1000000">$1,000,000</option>
                    <option value="1250000">$1,250,000</option>
                    <option value="1500000">$1,500,000</option>
                    <option value="1750000">$1,750,000</option>
                    <option value="2000000">$2,000,000</option>
                    <option value="2500000">$2,500,000</option>
                    <option value="3000000">$3,000,000</option>
                    <option value="5000000">$5,000,000</option>
                  </select>
                </div>
                <div className="next-button-div">
                  <button className="step-button" onClick={nextStep}>Next »</button>
                </div>
              </div>)}

              {/* end */}

              {step === 2 && (<div className="">
                <div className="col-lg-12 form-group">
                  <label htmlFor="name">First name</label>
                  <input type="text" placeholder="Your First Name" name="FirstName" value={formData.FirstName} className="form-control" onChange={handleChange} required />
                </div>
                <div className="col-lg-12 form-group">
                  <label>Date of Birth</label>
                  <div className="row">
                    <div className="col-4 col-lg-4">
                      <select className="form-control" name="mm" value={formData.mm} onChange={handleChange} required>
                        <option>Month</option>
                        <option value="01">1</option>
                        <option value="02">2</option>
                        <option value="03">3</option>
                        <option value="04">4</option>
                        <option value="05">5</option>
                        <option value="06">6</option>
                        <option value="07">7</option>
                        <option value="08">8</option>
                        <option value="09">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                    </div>
                    <div className="col-4 col-lg-4">
                      <select className="form-control" name="dd" value={formData.dd} onChange={handleChange} required>
                        <option>Day</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                      </select>
                    </div>
                    <div className=" col-4 col-lg-4">
                      <input
                        className="form-control"
                        name="yy"
                        type="number"
                        placeholder="Year"
                        min="1915"
                        max="2007"
                        value={formData.yy}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 form-group">
                  <label htmlFor="gender">Gender</label>
                  <select id="gender" className="form-control" name="g" value={formData.g} onChange={handleChange} required>
                    <option value="">Select Gender</option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                  </select>
                </div>
                {/* smoker non smoker */}

                <div className="col-lg-12 form-group">
                  <label htmlFor="tobacco">Smoking Status</label>
                  <select id="tobacco" className="form-control" name="tucgt" value={formData.tucgt} onChange={handleChange} required>
                    <option value="">Smoking Status</option>
                    <option value="0">Smoker</option>
                    <option value="100">Non Smoker</option>
                  </select>
                </div>
                <div className="step-button-div">
                  <button className="step-button" onClick={prevStep}>« Previous</button>
                  <button className="step-button" onClick={nextStep}>Next »</button>
                </div>
              </div>)}

              {/* end */}


              {step === 3 && (
                <div>

                <div>
                <div className="col-lg-12 form-group">
                  <label htmlFor="email">Your email</label>
                  <input
                    className="form-control"
                    id="email"
                    type="email"
                    placeholder="name@gmail.com"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  {!emailValid && (<span style={{ color: 'red' }}>Please enter a valid email address</span>)}
                </div>

                <div className="col-lg-12 form-group">
                  <label htmlFor="number">Phone Number</label>
                  <input
                    className="form-control"
                    id="number"
                    type="text"
                    placeholder="Enter Your Number"
                    name="number"
                    value={formData.number}
                    onChange={handleChange}
                    required
                  />
                  {!mobileNumberValid && (<span style={{ color: 'red' }}>Please enter a valid Canadian mobile number (e.g. 123-456-7890)</span>)}
                </div>
              </div>



              <div className="col-lg-12 form-group">
                <input type="checkbox" style={{ marginRight: "5px" }} />
                <span>
                  Sign up for weekly money stories, exclusive offers, and personal
                  finance tips from the pros. You may opt-out anytime.
                </span>
              </div>
              <div className="previous-button-div">
                <button className="step-button" onClick={prevStep}>« Previous</button>
              </div>

              <div className="col-lg-12 d-flex justify-content-center mt-5 btn-div">
                <button className="btn themebtn" type="submit"  disabled={formData.lc === "" || formData.pi === "" || formData.fa === "" || formData.FirstName === "" || formData.mm === "" || formData.dd === "" || formData.yy === "" || formData.g === "" || formData.tucgt === "" || formData.email === "" || formData.number === "" || !mobileNumberValid ? true : false}>
                  Get your free quotes
                </button>
              </div>
                </div>
              )}
              
            </div>

          </form>
          <div className="mt-5 companies">
            <div className="text-center">
              <p className="companies-text py-3">We compare insurance rates from Canada's top life insurance provider company.</p>
              <img className="w-100" src={companies} alt=""></img>
            </div>
          </div>
        </div>
        <footer>
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-6">
                <p>Ontario, Canada</p>
              </div>
              <div className="col-lg-6 d-flex justify-content-end">
                <p>
                  Copyright © 2023 policygem.ca All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default App;
